import React from 'react'
import PropTypes from 'prop-types'
import ArrowRight from '../Icon/ArrowRight'
import { Container } from '../../../layouts'
import * as S from './TextImage.css'
import * as G from '../../Global/Heading/Heading.css'

const TextImage = ({ image, heading, content, link, flex, rtl, first }) => {
  return (
    <S.TextImage rtl={rtl} flex={flex} first={first}>
      <S.Image src={image} alt={heading} />
      <S.Text>
        <Container large>
          <S.Box>
            <G.Heading2Size3 flex uppercase>
              {heading}
              {link && (
                <S.A to={link}>
                  <ArrowRight />
                </S.A>
              )}
            </G.Heading2Size3>
            <p>{content}</p>
          </S.Box>
        </Container>
      </S.Text>
    </S.TextImage>
  )
}

TextImage.propTypes = {
  image: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.string,
  rtl: PropTypes.bool,
  flex: PropTypes.bool,
}

export default TextImage
