import styled, { css } from 'styled-components'
import { media } from '../../lib/constants'

const getColWidth = (number) => {
  return (number / 12) * 100
}

export const Col = styled.div`
  width: 100%;

  ${({ image }) =>
    image &&
    css`
      background-image: url(${image});
      background-size: cover;
      background-repeat: no-repeat;

      img {
        opacity: 0;
      }
    `}
  ${({ xs }) =>
    xs &&
    css`
      @media (max-width: 575px) {
        width: ${getColWidth(xs)}%;
        ${({ offset }) => `margin-left: ${getColWidth(offset)}%;`}
      }
    `}

  ${({ sm }) =>
    sm &&
    css`
      @media (min-width: 576px) {
        width: ${getColWidth(sm)}%;
        ${({ offset }) => `margin-left: ${getColWidth(offset)}%;`}
      }
    `}
    
  ${({ md }) =>
    md &&
    css`
      @media (min-width: 768px) {
        width: ${getColWidth(md)}%;
        ${({ offset }) => `margin-left: ${getColWidth(offset)}%;`}
      }
    `}

  ${({ lg }) =>
    lg &&
    css`
      @media (min-width: 992px) {
        width: ${getColWidth(lg)}%;
        ${({ offset }) => `margin-left: ${getColWidth(offset)}%;`}
      }
    `}

  ${({ xl }) =>
    xl &&
    css`
      @media (min-width: 1200px) {
        width: ${getColWidth(xl)}%;
        ${({ offset }) => `margin-left: ${getColWidth(offset)}%;`}
      }
    `}
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ itemsCenter }) =>
    itemsCenter &&
    css`
      align-items: center;

      > ${Col} {
        height: 100%;
      }

      ${media.tablet`
        > ${Col} {
          height: auto;
        }
      `}
    `}

  ${({ gutter }) =>
    gutter &&
    css`
      margin: 0 -${gutter}px;

      > ${Col} {
        padding: 0 ${gutter}px;
      }
    `}

  ${({ rowReverse }) =>
    rowReverse &&
    css`
      flex-direction: row-reverse;
    `}


  ${media.desktop`
    ${({ columnsReverseTablet }) =>
      columnsReverseTablet &&
      css`
        flex-direction: column-reverse;
      `}
  `} 

  ${media.tablet`
    ${({ columnsReverseMobile }) =>
      columnsReverseMobile &&
      css`
        flex-direction: column-reverse;
      `}

    ${({ mobileGutter }) =>
      mobileGutter &&
      css`
        margin: 0 -${mobileGutter}px;
        > ${Col} {
          padding: 0 ${mobileGutter}px;
        }
      `}
  `}
`
