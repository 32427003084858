import styled from 'styled-components'
import {
  media,
  fonts,
  colors,
  textSm,
  shadows,
} from '../../../../lib/constants'
import Heading from '../../Global/Heading'

export const Testimonials = styled.div`
  padding-top: 4rem;

  ${media.tablet`
    padding-top: 1.5rem;
  `}
`

export const Image = styled.img`
  margin-bottom: 0;
  width: 100%;
  height: 535px;

  ${media.tablet`
    height: 400px;
  `}
`

export const CarouselWrap = styled.div`
  background: ${colors.white};
  padding: 3.75rem 3.5rem 3.75rem;
  position: absolute;
  top: -3.75rem;
  right: 0;
  width: 490px;
  min-height: 100%;
  box-shadow: ${shadows.projectCard};
  border-radius: 10px;

  .carousel .control-dots {
    text-align: left;
    bottom: 0;
    margin: 0;

    .dot {
      margin-left: 0;
      margin-right: 10px;
      width: 10px;
      height: 10px;
      background-color: ${colors.silve};
      box-shadow: none;

      &.selected {
        background-color: ${colors.pink};
      }

      &:before {
        display: none;
      }
    }
  }

  ${media.tablet`
    position: relative;
    width: 100%;
    padding: 2.5rem;
  `}

  ${media.phone`
    padding: 1.5rem;
  `}
`

export const SliderItem = styled.div`
  text-align: left;
  padding-bottom: 50px;
`

export const Logo = styled.img`
  height: 60px !important;
  width: auto !important;
  margin-bottom: 1rem;
`

export const Content = styled.div`
  font-size: 22px;
  line-height: 1.4;
`

export const Info = styled.div`
  position: relative;
  margin-top: 1.5rem;
  padding-top: 2rem;

  &::after {
    position: absolute;
    height: 5px;
    width: 100px;
    content: ' ';
    left: 0;
    top: 0;
    background-color: ${colors.pink};
  }
`

export const Title = styled.span`
  font-family: ${fonts.headings};
  font-weight: 400;
  ${textSm}
`

export const Name = styled(Heading)`
  font-family: ${fonts.headings};
  margin-bottom: -5px;
  font-size: 1rem;
`
