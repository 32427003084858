import React from 'react'
import { Row, Col } from '../../../layouts/grid.css'
import { Carousel } from 'react-responsive-carousel'
import TestimonialsImg from '../../../../static/img/team-success.svg'
import * as S from './Testimonials.css'

const Testimonials = ({ testimonials }) => {
  const settings = {
    infiniteLoop: true,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    interval: 6000,
    transitionTime: 600,
    autoPlay: true,
  }

  return (
    <S.Testimonials>
      <Row>
        <Col md={8}>
          <S.Image src={TestimonialsImg} alt="" />
        </Col>
        <Col md={4} style={{ position: 'relative' }}>
          <S.CarouselWrap>
            <Carousel {...settings}>
              {testimonials.map((it, index) => (
                <S.SliderItem key={index}>
                  <S.Logo src={it.client_logo.publicURL} alt={it.client_name} />
                  <S.Content>{it.content}</S.Content>
                  <S.Info>
                    <S.Name level={4}>{it.client_name}</S.Name>
                    <S.Title>{it.title}</S.Title>
                  </S.Info>
                </S.SliderItem>
              ))}
            </Carousel>
          </S.CarouselWrap>
        </Col>
      </Row>
    </S.Testimonials>
  )
}

export default Testimonials
