import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { buttons, colors, media, textMd } from '../../../../lib/constants'

export const TextImage = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};

  > div > div,
  > div > div > div {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    height: 100%;
    flex: 1;
    align-items: center;
  }

  ${media.desktop`
    padding: 3rem 0;

    ${(props) =>
      props.first &&
      css`
        padding-top: 0;
      `}
  `}

  ${media.tablet`
    padding: 0;
    flex-direction: column;
    margin-bottom: calc(100rem/18);
  `}

  ${(props) =>
    !props.rtl &&
    css`
      > div > div,
      > div > div > div {
        justify-content: flex-end;
      }
    `}
`

export const Image = styled.img`
  width: 55%;

  ${media.tablet`
    width: 100%;
  `}
`

export const Box = styled.div``

export const Text = styled.div`
  flex: 1;
  ${textMd}
  line-height: 1.4;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  align-items: center;

  ${Box} {
    max-width: 41.666%;
  }

  ${media.tablet`
    padding: 0;
    position: relative;

    ${Box} {
      max-width: 100%;
    }
  `}
`

export const A = styled(Link)`
  ${buttons.pinkhollow}
  background-color: ${colors.pink};
  color: ${colors.white};
  cursor: pointer;
  padding: 0;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  flex 0 0 40px;
  margin-left: calc(32rem/18);

  &::before {
    display: none;
  }
`
