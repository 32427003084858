import React from 'react'
import TextImage from '../../Global/TextImage'
import { Container } from '../../../layouts'
import { Row, Col } from '../../../layouts/grid.css'
import * as S from './Services.css'
import * as G from '../../Global/Heading/Heading.css'

const Services = ({ title, content, services }) => {
  return (
    <>
      <Container large>
        <G.Heading2Size3 hasLine center>
          {title}
        </G.Heading2Size3>
        <S.ServiceBoxes>
          <Row gutter={30} itemsCenter>
            <Col lg={4}>
              <S.ServiceBox>
                <G.Heading3Size4>Listeners</G.Heading3Size4>
                <S.ServiceBoxText>
                  Success starts with understanding your business wants, and
                  needs.
                </S.ServiceBoxText>
              </S.ServiceBox>
            </Col>
            <Col lg={4}>
              <S.ServiceBox>
                <G.Heading3Size4 level={4}>Explorers</G.Heading3Size4>
                <S.ServiceBoxText>
                  Workshops, discovery and design to level-up your thinking.
                </S.ServiceBoxText>
              </S.ServiceBox>
            </Col>
            <Col lg={4}>
              <S.ServiceBox>
                <G.Heading3Size4 level={4}>Architects</G.Heading3Size4>
                <S.ServiceBoxText>
                  Products built on strong foundations of quality data and
                  insights.
                </S.ServiceBoxText>
              </S.ServiceBox>
            </Col>
            <Col lg={4} offset={2}>
              <S.ServiceBox>
                <G.Heading3Size4 level={4}>Makers</G.Heading3Size4>
                <S.ServiceBoxText>
                  We built lots of things, like cloud platforms, websites, and
                  apps.
                </S.ServiceBoxText>
              </S.ServiceBox>
            </Col>
            <Col lg={4}>
              <S.ServiceBox>
                <G.Heading3Size4 level={4}>Growers</G.Heading3Size4>
                <S.ServiceBoxText>
                  Simple, scaleable solutions to complex problems.
                </S.ServiceBoxText>
              </S.ServiceBox>
            </Col>
          </Row>
        </S.ServiceBoxes>
      </Container>

      {services.length > 0 &&
        services.map(
          ({ service }, key) =>
            service && (
              <TextImage
                key={key}
                rtl={key % 2 === 0}
                first
                image={service.frontmatter.icon.publicURL}
                heading={service.frontmatter.home_title}
                content={service.frontmatter.home_text}
              />
            )
        )}
    </>
  )
}

Services.defaultProps = {}

Services.propTypes = {}

export default Services
