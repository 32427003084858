import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Container } from '../../../layouts'
import cloud_1 from './images/clouds-1.svg'
import cloud_2 from './images/clouds-2.svg'
import cloud_3 from './images/clouds-3.svg'
import * as S from './Hero.css'

const cloudImages = [
  <img key={1} className="cloud_1" src={cloud_1} alt="" />,
  <img key={2} className="cloud_2" src={cloud_2} alt="" />,
  <img key={3} className="cloud_3" src={cloud_3} alt="" />,
]

const Hero = ({ data }) => {
  const settings = {
    infiniteLoop: true,
    showArrows: false,
    showStatus: false,
    showThumbs: false,
    interval: 5000,
    transitionTime: 600,
    autoPlay: true,
    renderIndicator: (onClickHandler, isSelected, index) => {
      if (isSelected) {
        return <S.Indicator active>0{index + 1} __</S.Indicator>
      }
      return (
        <S.Indicator
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          tabIndex={0}
        >
          0{index + 1}
        </S.Indicator>
      )
    },
  }

  return (
    <S.Hero>
      <S.HeroInner>
        <Container>
          <S.CarouselWrap>
            <Carousel {...settings}>
              {data.map((it, key) => (
                <S.SliderItem key={key}>
                  <p>{it.content}</p>
                  <h2>{it.title}</h2>
                </S.SliderItem>
              ))}
            </Carousel>
          </S.CarouselWrap>
        </Container>
      </S.HeroInner>
      <S.Clouds>{cloudImages}</S.Clouds>
    </S.Hero>
  )
}

export default Hero
