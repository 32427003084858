import styled, { css } from 'styled-components'
import { colors, fonts, media, textSm } from '../../../../lib/constants'

export const Hero = styled.div`
  position: relative;
  color: ${colors.white};
  background-color: ${colors.blue};
  min-height: 700px;
  height: 60vh;
  overflow: hidden;

  ${media.wide`
    min-height: 600px;
    height: 50vh;
  `}

  ${media.phone`
    min-height: 450px;
    height: 450px;
  `}

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
      background-position: right bottom;
      background-size: cover;
      background-repeat: no-repeat;
    `}

  ${media.tablet`
    ${({ mobileBackground }) =>
      mobileBackground &&
      css`
        background-image: url(${mobileBackground});
        background-position: right bottom;
        background-size: cover;
        background-repeat: no-repeat;
      `}
  `}
`

export const HeroInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

export const CarouselWrap = styled.div`
  max-width: 60%;

  .carousel .control-dots {
    display: flex;
    margin-bottom: 0;
    padding: 0 1rem;
  }

  .carousel .slide {
    text-align: left;
  }

  ${media.wider`
    max-width: 70%;
  `}

  ${media.wide`
    max-width: 80%;
  `}

  ${media.tablet`
    max-width: 100%;
    padding-top: 200px;

    .carousel .control-dots {
      position: absolute;
      bottom: 1rem;
      margin-top: 55px;
      margin-bottom: 28px;
    }
  `}
  ${media.phone`
    padding-top: 100px;
  `}
`

export const Indicator = styled.div`
  font-family: ${fonts.headings};
  font-size: 1.3888rem;
  color: ${colors.grey__light};
  margin-right: 1.625rem;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: ${colors.pink};
    `}
`

export const SliderItem = styled.div`
  padding: 6rem 1rem 4rem;

  h2 {
    color: ${colors.white};
    font-size: calc(64rem / 18);
    font-family: ${fonts.headings};
    font-weight: 700;
    position: relative;
    line-height: 1.2;
    margin-bottom: 2rem;
  }

  p {
    font-family: ${fonts.script};
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
    color: ${colors.pink};
  }

  ${media.tablet`
    padding-top: 0;
    padding-bottom: 6rem;

    h2 {
      font-size: calc(36rem /18);
      margin-bottom: 0.75rem;

      &:before {
        height: 5px;
        width: 60px;
      }
    }

    p {
      font-size: 1.25rem;
    }
  `}
`

/* clouds */
export const Clouds = styled.div`
  @keyframes float {
    0% {
      bottom: -2rem;
    }
    25% {
      bottom: -3rem;
    }
    50% {
      bottom: -2.5rem;
    }
    75% {
      bottom: -3.5rem;
    }
    100% {
      bottom: -2rem;
    }
  }

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  img {
    width: 100%;
  }
  .cloud_1 {
    position: absolute;
    bottom: -5rem;
    margin: 0;
    ${media.tablet`bottom: -2rem;`}
  }
  .cloud_2 {
    position: absolute;
    bottom: -2rem;
    margin: 0;
    animation-name: float;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    ${media.tablet`
      bottom: 0rem;
      // animation: none;
    `}
  }
  .cloud_3 {
    position: absolute;
    bottom: -3rem;
    margin: 0;
    ${media.tablet`bottom: -1rem;`}
  }
`
