import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Layout, Container } from '../layouts'
import { Helmet } from 'react-helmet'
import Heading from '../components/Global/Heading'
import Hero from '../components/Home/Hero'
import OurClients from '../components/Home/OurClients'
import Projects from '../components/Home/Projects'
import Services from '../components/Home/Services'
import Testimonials from '../components/Company/Testimonials'
import CTA from '../components/Global/CTA'
import Video from '../components/Global/Video'
import { colors } from '../../lib/constants'

export const IndexPageTemplate = ({
  hero_slides,
  clients_section,
  services_section,
  featured_projects,
  testimonials,
  cta,
  video,
}) => {
  return (
    <>
      <Helmet>
        <title>
          Development & design for Websites, platforms & Apps - TechEquipt
        </title>
        <meta
          name="description"
          content="TechEquipt believes success starts via exploration, discovery & design backed with data and insights. We create simple, scalable solutions to complex problems."
        />
      </Helmet>

      <Hero data={hero_slides} />

      {featured_projects && featured_projects.projects.length > 0 && (
        <Container padding="8rem 0 4rem">
          <Heading hasLine center>
            {featured_projects.title}
          </Heading>
          <Projects projects={featured_projects.projects} />
        </Container>
      )}

      {video?.video_url && (
        <Container padding="8rem 0 4rem">
          <Video
            url={video.video_url}
            rounded
            thumbnail={video?.video_thumbnail?.publicURL || ''}
          />
        </Container>
      )}

      {services_section && services_section.items.length > 0 && (
        <Services
          title={services_section.title}
          content={services_section.content}
          services={services_section.items}
        />
      )}

      {testimonials && testimonials.length > 0 && (
        <Container large>
          <Heading hasLine center>
            Our success
          </Heading>
          <Testimonials testimonials={testimonials} />
        </Container>
      )}

      {clients_section && clients_section.items.length > 0 && (
        <Container large>
          <OurClients
            title={clients_section.title}
            content={clients_section.content}
            clients={clients_section.items}
          />
        </Container>
      )}

      {cta && cta.title && cta.image.publicURL && (
        <Container color={colors.blue}>
          <CTA
            heading={cta.title}
            content={cta.content}
            btnText={cta.button_text}
            btnLink={cta.button_link}
            image={cta.image.publicURL}
          />
        </Container>
      )}
    </>
  )
}

const IndexPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data
  return (
    <Layout>
      <IndexPageTemplate {...frontmatter} />
    </Layout>
  )
}

IndexPageTemplate.propTypes = {
  hero_slides: PropTypes.array,
  intro: PropTypes.object,
  services: PropTypes.array,
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero_slides {
          title
          content
        }
        services_section {
          title
          content
          items {
            service {
              frontmatter {
                slug
                home_title
                home_text
                icon {
                  publicURL
                }
              }
            }
          }
        }
        clients_section {
          title
          content
          items {
            name
            logo {
              publicURL
            }
            link
          }
        }
        featured_projects {
          title
          projects {
            project {
              fields {
                slug
              }
              frontmatter {
                project_title
                feature_image {
                  publicURL
                }
                home_image {
                  publicURL
                }
                description
                client
              }
            }
          }
        }
        testimonials {
          client_name
          title
          content
          client_logo {
            publicURL
          }
        }
        cta {
          title
          content
          image {
            publicURL
          }
          button_text
          button_link
        }
        video {
          video_url
          video_thumbnail {
            publicURL
          }
        }
      }
    }
  }
`
