import React from 'react'
import LazyLoad from 'react-lazyload'
import Heading from '../../Global/Heading'
import * as S from './OurClients.css'
import * as G from '../../Global/Heading/Heading.css'

const OurClients = ({ title, content, clients }) => {
  return (
    <S.OurClients>
      <S.List>
        {clients.map(({ logo, name }, key) => (
          <S.ListItem key={key}>
            <div>
              <LazyLoad height={204} once offset={200}>
                <S.Logo src={logo.publicURL} alt={name} />
              </LazyLoad>
            </div>
          </S.ListItem>
        ))}
      </S.List>
      <S.Info>
        <G.Heading2Size3 uppercase style={{ marginBottom: '0.75rem' }}>
          {title}
        </G.Heading2Size3>
        <S.Content>{content}</S.Content>
      </S.Info>
    </S.OurClients>
  )
}

OurClients.propTypes = {}

OurClients.defaultProps = {}

export default OurClients
