import styled from 'styled-components'
import { colors, media, shadows } from '../../../../lib/constants'

export const OurClients = styled.div`
  padding-bottom: 12rem;

  ${media.desktop`
    padding-bottom: 6rem;
  `}

  ${media.tablet`
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0;
  `}
`

export const List = styled.div`
  position: relative;
`

export const ListItem = styled.div`
  display: flex;
  width: 204px;
  height: 204px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  position: absolute;
  box-shadow: ${shadows.projectCard};
  border-radius: 10px;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:nth-child(1) {
    left: 0;
  }

  &:nth-child(2) {
    left: 224px;
    top: -65px;
  }

  &:nth-child(3) {
    top: 224px;
  }

  &:nth-child(4) {
    top: 159px;
    left: 224px;
  }

  &:nth-child(5) {
    left: 448px;
    top: 224px;
  }

  ${media.desktop`
    width: 150px;
    height: 150px;

    &:nth-child(2) {
      left: 165px;
      top: -45px;
    }
  
    &:nth-child(3) {
      top: 165px;
    }
  
    &:nth-child(4) {
      top: 119px;
      left: 165px;
    }
  
    &:nth-child(5) {
      left: 330px;
      top: 165px;
    }
  `}

  ${media.tablet`
    position: relative;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    width: 100%;
    padding-bottom: 100%;
    margin-bottom: 1rem;
    height: 0;

    &::after {
      padding-bottom: 100%;
      content: " ";
      display: block;
    }
  `}
`

export const Logo = styled.img`
  margin-bottom: 0;
  max-width: calc(100% - 50px);
`

export const Info = styled.div`
  padding-left: 500px;

  ${media.desktop`
    padding-left: 360px;
  `}

  @media (min-width: 768px) and (max-width: 790px) {
    h3 {
      font-size: 2.25rem;
    }
  }

  ${media.tablet`
    padding-left: 0;
    margin-bottom: 60px;
  `}
`

export const Content = styled.div`
  font-size: 24px;
  line-height: 1.3;

  ${media.desktop`
    font-size: 1rem;
  `}
`
