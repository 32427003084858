import styled from 'styled-components'
import {
  media,
  colors,
  textMd,
  shadows,
  transitions,
} from '../../../../lib/constants'
import Heading from '../../Global/Heading'

export const ServiceContext = styled.div`
  h2 {
    margin-bottom: 0;
  }

  ${media.tablet`
    h2 {
      margin-bottom: 2rem;
    }
  `}
`

export const ServiceBox = styled.div`
  padding: 2rem;
  background: white;
  box-shadow: none;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto 3rem;
  box-shadow: ${shadows.projectCard};
  max-width: 450px;
`

export const ServiceBoxTitle = styled(Heading)`
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`

export const ServiceBoxText = styled.p`
  margin-bottom: 0;
  font-weight: 500;
`

export const ServiceText = styled.p`
  margin-bottom: 0;
  padding: 1.5rem;
  background-color: ${colors.white};
  font-weight: 500;
  border-radius: 10px;
  ${textMd}
`

export const ServiceBoxes = styled.section`
  margin-top: 4rem;

  & > div > div > div {
    transition: transform ${transitions.slow};
    &:hover {
      transform: rotate(0deg) !important;
    }
  }

  // first
  & > div > div:nth-child(1) > div {
    transform: rotate(-5deg);
  }
  // second
  & > div > div:nth-child(2) > div {
    transform: rotate(2deg);
  }
  // third
  & > div > div:nth-child(3) > div {
    transform: rotate(-3deg);
  }
  // fourth
  & > div > div:nth-child(4) > div {
    transform: rotate(3deg);
  }
  // fifth
  & > div > div:nth-child(5) > div {
    transform: rotate(-2deg);
  }
`
