import PropTypes from 'prop-types'
import React from 'react'
import * as S from './Button.css'

const Button = ({
  type,
  children,
  href,
  primary,
  border,
  transparent,
  block,
  style,
}) => {
  const styleProps = {
    primary,
    border,
    transparent,
    block,
    style,
  }

  return type === 'link' ? (
    <S.A {...styleProps} to={href}>
      {children}
    </S.A>
  ) : (
    <S.Button {...styleProps} type={type}>
      {children}
    </S.Button>
  )
}

Button.defaultProps = {
  type: 'button',
  primary: true,
  border: false,
}

Button.propsType = {
  type: PropTypes.oneOf(['link', 'button', 'submit']),
  href: PropTypes.string,
  primary: PropTypes.bool,
  border: PropTypes.bool,
  transparent: PropTypes.bool,
}

export default Button
