import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { buttons, colors, fonts } from '../../../../lib/constants'

const buttonBase = css`
  ${buttons.pinkhollow}
  font-size: 1rem;
  padding: 1rem 1.75rem;
  text-align: center;
  align-items: center;
  justify-content: center;

  svg,
  span {
    margin: 0 5px;
  }

  ${(props) =>
    props.block &&
    css`
      width: 100%;
    `}

  ${(props) =>
    props.primary &&
    css`
      background-color: ${colors.pink};
      color: ${colors.white};
      font-family: ${fonts.headings};
      font-weight: 700;
      cursor: pointer;
      justify-content: center;
    `}

  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
      border-color: transparent;
      color: ${colors.pink} !important;
      font-family: ${fonts.headings};
      font-weight: 700;
      padding: 0;
      display: inline-flex;
      align-items: center;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: ${colors.pink} !important;

        &:after {
          display: none;
        }
      }
    `}
`

export const Button = styled.button`
  ${buttonBase}
`

export const A = styled(Link)`
  ${buttonBase}
  display: inline-flex;
  justify-content: center;
  font-weight: 700;

  &:before {
    display: none;
  }
`
